@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Lato';
  src: url('./Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  src: url('./Lato-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url('./Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: italic;
  src: url('./Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url('./Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: url('./Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: italic;
  src: url('./Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: url('./Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: italic;
  src: url('./Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Javanese';
  src: url('./NotoSansJP-Thin.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Javanese';
  font-weight: 300;
  src: url('./NotoSansJP-Light.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Javanese';
  font-weight: 400;
  src: url('./NotoSansJP-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Javanese';
  font-weight: 500;
  src: url('./NotoSansJP-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Javanese';
  font-weight: 700;
  src: url('./NotoSansJP-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Javanese';
  font-weight: 900;
  src: url('./NotoSansJP-Black.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Color Emoji';
  font-weight: 900;
  src: url('./NotoColorEmoji-Regular.ttf') format('truetype');
}

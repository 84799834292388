.pdf-container-render {
  width: 100%;
  max-height: 600px;
  margin: 0px 30px 30px 30px;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.pdf-container-render::-webkit-scrollbar {
  width: 7.57px;
  height: 7.57px;
}

.pdf-container-render::-webkit-scrollbar-track {
  box-shadow: rgb(170, 170, 170);
}

.pdf-container-render::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.pdf-container-render::-webkit-scrollbar-thumb-horizontal {
  background: #c4c4c4;
  border-radius: 10px;
}

.zoom-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  background-color: #f4f4f4;
}

.zoom-icon {
  cursor: pointer;
  margin: 0 3px;
  width: 30px;
  height: 30px;
}

.zoom-icon:hover {
  opacity: 0.7;
}

.pdf-container {
  width: 100%;
  height: 600px;
  position: relative;
}

.ant-modal-footer .ant-btn {
  width: 152px !important;
  height: 43px !important;
  border-radius: 5px;
  font-size: 13px;
}

.ant-modal-footer .ant-btn:hover {
  background: inherit;
  border-color: inherit;
}

.ant-modal-footer .ant-btn-primary {
  color: #fff;
  border-color: #f6ac00;
  background: #f6ac00;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-modal-footer .ant-btn-primary:hover {
  border-color: #f6ac00;
  background: #f6ac00;
}

.page-wrapper {
  margin-bottom: 20px; /* Add spacing between pages */
}

.page-wrapper {
  margin-bottom: 20px; /* Add spacing between pages */
}
